import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

import { AuthService } from '@shared/auth/auth.service';
import { CurrentProjectService } from '@shared/cookies/current-project.service';
import { Project } from '@models/project';
import { renderWidget } from '@magicbell/embeddable';
import { environment } from '@environments/environment';
import { ProjectsModalComponent } from '@shared/projects/projects-modal/projects-modal.component';
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [CurrentProjectService]
})

export class NavbarComponent implements OnInit {
  @ViewChild(ProjectsModalComponent) projectsModalComponent: ProjectsModalComponent;
  @ViewChild('notifications') notifications: ElementRef;

  toggleClass = 'ft-maximize';
  placement = 'bottom-right'
  public isCollapsed = true;
  user_name:string = '';
  user_image_url:string  = '';
  user: any;


  // Custom Variables
  currentProject: Project = this.currentProjectService.getCurrentProject();

  constructor(
    private authService: AuthService,
    private currentProjectService: CurrentProjectService
  ) {
    const helper = new JwtHelperService();
    let token = this.authService.getToken();
    console.log(token)
    this.user = helper.decodeToken(token);
  }

  ngOnInit() {
    setTimeout(() => {
      const wrapperDiv = document.getElementsByClassName(`wrapper`)[0];
      if(!wrapperDiv)
        return this.placement = 'bottom-right'
      const dir = wrapperDiv.getAttribute(`dir`);
      if (dir === 'rtl') {
          this.placement = 'bottom-left';
      } else if (dir === 'ltr') {
          this.placement = 'bottom-right';
      }
    }, 3000);
    this.setProfileImg();
    this.renderNotifications();
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize'
    }
  }

  renderNotifications() {    
    if ([0, 1, 2].some(role => this.user.role_name_enums.includes(role))) {
      renderWidget(this.notifications.nativeElement, {
        apiKey: environment.MAGIC_BELL_API_KEY,
        userEmail: this.user.email,
        theme: {
          icon: {
            borderColor: '#263238',
            width: '20px'
          }
        },
        locale: 'es'
      });
    }
  }
  

  setProfileImg(){
    let top = 30;
    this.user_name = `${this.user.name} ${this.user.last_name}`;
    if(this.user.user_image_url){
      this.user_image_url = this.user.user_image_url;
    }
    if(this.user_name.length > top){
      let str = this.user_name.split(' ');
      this.user_name = '';
      for(let i = 0; i < str.length; i++){
        if(str[i].length > top){
          this.user_name += `${str[i].substr(0,top)}...`;
          break;
        }
        this.user_name += `${str[i]} `;
        if(this.user_name.length >= 30){
          this.user_name += '...';
          break
        }
      }
    }
  }

  logout() {
    this.authService.logout();
  }

  // Custom methods
  openProjectsModal(): void {
    this.projectsModalComponent.openProjectsModal();
  }

  setCurrentProject(project): void {
    this.currentProjectService.setCurrentProject(project);
  }
}
